<template>
  <b-card v-if="clientData">
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row>
      <b-col
        md="6"
        lg="6"
      >
        <validation-observer

          ref="formClient"
        > <b-form
          class="p-2"
        >
          <validation-provider
            #default="{errors}"
            :name="$t('Client/Company Name')"
            rules="required"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Client/Company Name"
                label-for="client-name"
              >
                <b-form-input
                  id="client-name"
                  v-model="clientData.name"
                  size="lg"
                  placeholder="Enter a client name..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Name -->
          <!-- Email -->
          <validation-provider
            #default="{errors}"
            :name="$t('Email')"
            rules="required|email"
          >
            <!-- Name -->
            <b-col cols="12">

              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="clientData.email"
                  size="lg"
                  placeholder="Enter a email..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Email -->
          <!-- billing address -->
          <b-col cols="12">
            <b-form-group
              label="Billing address"
              label-for="billing-address"
            >
              <b-form-textarea
                id="billing-address"
                v-model="clientData.billing_address"
                size="lg"
                rows="4"
                placeholder="Enter a description"
              />
            </b-form-group>
          </b-col>
          <!--/ billing address-->
          <!-- Contact name -->
          <validation-provider
            #default="{errors}"
            :name="$t('Contact name')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Contact name"
                label-for="contact-name"
              >
                <b-form-input
                  id="contact-name"
                  v-model="clientData.contact_name"
                  size="lg"
                  placeholder="Enter a contact name..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ contact name-->
          <!-- phone -->
          <validation-provider
            #default="{errors}"
            :name="$t('Phone')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="clientData.phone"
                  size="lg"
                  placeholder="Enter a phone..."
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ phone-->
          <!-- mobile -->
          <validation-provider
            #default="{errors}"
            :name="$t('Mobile')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Mobile"
                label-for="mobile"
              >
                <b-form-input
                  id="mobile"
                  v-model="clientData.mobile"
                  size="lg"
                  placeholder="Enter a mobile number..."
                  :state="errors.length > 0 ? false:null"
                  type="number"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ mobile-->
          <!-- website -->
          <validation-provider
            #default="{errors}"
            :name="$t('Website')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Website"
                label-for="website"
              >
                <b-form-input
                  id="website"
                  v-model="clientData.website"
                  size="lg"
                  placeholder="Enter a website..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ website-->
          <!-- Tax number -->
          <validation-provider
            #default="{errors}"
            :name="$t('Tax number')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Tax number"
                label-for="tax-number"
              >
                <b-form-input
                  id="tax-number"
                  v-model="clientData.tax_number"
                  size="lg"
                  placeholder="Enter a tax number..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ tax number-->
          <!-- Notes -->
          <validation-provider
            #default="{errors}"
            :name="$t('Notesr')"
            rules=""
          >

            <b-col cols="12">

              <b-form-group
                label="Notes"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="clientData.notes"
                  size="lg"
                  rows="4"
                  placeholder="Enter a description"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Notes-->
          <b-col
            cols="12"
            class="mt-3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="saveData"
            >
              Save
            </b-button>

          </b-col>
        </b-form>
        </validation-observer>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import clientStoreModule from './clientStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currencyClaveOptions: null,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Client Updated',
      errorData: null,
      blankClientData: {
        id: 0,
        name: '',
        email: '',
        billing_address: '',
        contact_name: '',
        phone: '',
        mobile: '',
        website: '',
        tax_number: '',
        notes: '',
      },
      clientData: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  created() {
    this.resetForm()

    let clientId = 0

    this.clientData.id = clientId

    if (this.$router.currentRoute.params.id) {
      clientId = this.$router.currentRoute.params.id
      this.fetchClient(clientId)
    }
  },
  methods: {
    fetchClient(id) {
      store
        .dispatch('app-client/fetchSingleClient', { id })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.clientData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },

    saveData() {
      this.$refs.formClient.validate().then(success => {
        if (success) {
          if (this.clientData.id === 0) {
            this.clientData.email = this.clientData.email.trim()
            store
              .dispatch('app-client/addClient', this.clientData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Client created'
                this.$router.push({ name: 'clients' })
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            store
              .dispatch('app-client/updateClient', this.clientData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Client updated'
                this.$router.push({ name: 'clients' })
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          }
        }
      })
    },
    resetForm() {
      this.clientData = JSON.parse(JSON.stringify(this.blankClientData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
